export const userInputs = [
    {
      id: "username",
      label: "Username",
      type: "text",
      placeholder: "john_doe",
    },
    {
      id: "displayName",
      label: "Name and surname",
      type: "text",
      placeholder: "John Doe",
    },
    {
      id: "email",
      label: "Email",
      type: "mail",
      placeholder: "john_doe@gmail.com",
    },
    {
      id: "phone",
      label: "Phone",
      type: "text",
      placeholder: "+1 234 567 89",
    },
    {
      id: "password",
      label: "Password (Minimum 6 characters)",
      type: "password",
    },
    {
      id: "address",
      label: "Address",
      type: "text",
      placeholder: "Elton St. 216 NewYork",
    },
    {
      id: "country",
      label: "Country",
      type: "text",
      placeholder: "USA",
    },
  ];
  
  export const productInputs = [
    {
      id: "productcode",
      label: "Product Code",
      type: "text",
      placeholder: "Product Codes",
    },
    {
      id: "title",
      label: "Title",
      type: "text",
      placeholder: "Title",
    },
    {
      id: "description",
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
    {
      id: "material",
      label: "Material",
      type: "text",
      placeholder: "Material",
    },
    {
      id: "category",
      label: "Category",
      type: "dropdown",
      placeholder: "Category",
    },
    {
      id: "subcategory",
      label: "Sub Category",
      type: "dropdown",
      placeholder: "Sub Category",
    },
    {
      id: "price",
      label: "Price",
      type: "number",
      placeholder: "price",
    },
    {
      id: "weight",
      label: "Weight",
      type: "text",
      placeholder: "Weight",
    },
    {
      id: "instock",
      label: "In Stock",
      type: "text",
      placeholder: "in stock",
    },
  ];
  