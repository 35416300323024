import React, { FC, useEffect, useId } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CollectionCard from "./CollectionCard";
import CollectionCard2 from "./CollectionCard2";
import { Link } from "react-router-dom";
import { DEMO_LARGE_PRODUCTS } from "./SectionSliderLargeProduct2";

export interface SectionSliderLargeProductProps {
  className?: string;
  itemClassName?: string;
  cardStyle?: "style1" | "style2";
}

const SectionSliderLargeProduct: FC<SectionSliderLargeProductProps> = ({
  className = "",
  cardStyle = "style2",
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  useEffect(() => {
    const OPTIONS: Glide.Options = {
      perView: 3,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          gap: 28,
          perView: 2.5,
        },
        1024: {
          gap: 20,
          perView: 2.15,
        },
        768: {
          gap: 20,
          perView: 1.5,
        },

        500: {
          gap: 20,
          perView: 1,
        },
      },
      type: "slider",
      startAt: 0,
      focusAt: "",
      autoplay: 0,
      hoverpause: false,
      keyboard: false,
      swipeThreshold: 0,
      dragThreshold: 0,
      perSwipe: "",
      touchRatio: 0,
      touchAngle: 0,
      animationDuration: 0,
      rewind: false,
      rewindDuration: 0,
      animationTimingFunc: "",
      waitForTransition: false,
      throttle: 0,
      direction: "ltr",
      peek: "",
      cloningRatio: 0,
      classes: {
        swipeable: "",
        dragging: "",
        direction: {
          ltr: 'glide--ltr',
          rtl: 'glide--rtl'
        },
        type: {
          slider: 'glide--ltr',
          carousel: 'glide--rtl'
        },
        slide:  {
          clone: 'glide--ltr',
          active: 'glide--rtl'
        },
        arrow: {
          disabled: 'glide--ltr'   
                },
        nav: {
          active: 'glide--ltr'   
                },
      }
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`);
    slider.mount();
    // @ts-ignore
    return () => {slider.destroy();
    };
  }, [UNIQUE_CLASS]);

  const MyCollectionCard =
    cardStyle === "style1" ? CollectionCard : CollectionCard2;

  return (
    <div className={`nc-SectionSliderLargeProduct ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading isCenter={false} hasNextPrev>
          Chosen by our experts
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {DEMO_LARGE_PRODUCTS.map((product, index) => (
              <li className={`glide__slide`} key={index}>
                <MyCollectionCard
                  name={product.name}
                  price={product.price}
                  imgs={product.images}
                  description={product.desc}
                />
              </li>
            ))}

            <li className={`glide__slide   `}>
              <Link to={"/page-search"} className="block relative group">
                <div className="relative rounded-2xl overflow-hidden h-[410px]">
                  <div className="h-[410px] bg-black/5 dark:bg-neutral-800"></div>
                  <div className="absolute inset-y-6 inset-x-10  flex flex-col items-center justify-center">
                    <div className="flex items-center justify-center relative">
                      <span className="text-xl font-semibold">More items</span>
                      <svg
                        className="absolute left-full w-5 h-5 ml-2 rotate-45 group-hover:scale-110 transition-transform"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.0701 9.57L12.0001 3.5L5.93005 9.57"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 20.4999V3.66992"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <span className="text-sm mt-1">Show me more</span>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderLargeProduct;
