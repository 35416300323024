import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import explore1Svg from "images/collections/explore1.svg";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

export interface CardCategory4Props {
  className?: string;
  featuredImage?: string;
  bgSVG?: string;
  name: string;
  desc: string;
  color?: string;
}

const CardCategory4: FC<CardCategory4Props> = ({
  
  className = "",
  featuredImage = ".",
  bgSVG = explore1Svg,
  name,
  desc,
  color = "bg-rose-50",
}) => {
  
  return (
    <div
      className={`nc-CardCategory4 relative w-full aspect-w-12 aspect-h-11 h-0 rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 group hover:nc-shadow-lg transition-shadow ${className}`}
      data-nc-id="CardCategory4"
    >
      <div>
       

        <div className="absolute inset-5 sm:inset-8 flex flex-col justify-between items-center">
          <div className="flex justify-between items-center">
            <NcImage
              src={featuredImage}
              containerClassName={`w-80 h-60 rounded-lg z-0 ${color}`}
            />
        
          </div>

          <div className="">
            <span
              className={`block mb-2 text-sm text-slate-500 dark:text-slate-400`}
            >
              {desc}
            </span>
            <h2 className={`text-2xl sm:text-3xl text-neutral-900`}>{name}</h2>
          </div>

          <Link
            to={`/page-collection-2/ ${name}`} 
            className="flex items-center text-sm font-medium group-hover:text-primary-500 transition-colors"
          >
          </Link>
        </div>
      </div>

      <Link  to={`/page-collection-2/${name}`} ></Link>
    </div>
  );
};

export default CardCategory4;
