import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { auth, db, storage } from "../../utils/firebase.js";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { productInputs, userInputs } from "../../formSource";
import { useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import  AuthContext  from "../../context/AuthContext"
import { useContext } from "react";
import { useHistory } from "react-router-dom";

const New = () => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [categorySelected, setCategorySelected] = useState('');
  const [subCategorySelected, setsubCategorySelected] = useState('');
  //let [inputs] = {userInputs};
  const [title] = "test";
   const { user } = useContext(AuthContext);
  const history = useHistory();

  console.log(user)
  if (!user) {
    history.push("/pageNotFound");
  }

  //const navigate = useNavigate()
  let location = useLocation();
  let inputData;

  location.pathname === "/addproducts" ? inputData = productInputs : inputData = userInputs;

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;


      console.log(name);
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, image: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);


  const handleInput = (e) => {
    const id = e.target.id;
    const value = id == 'price' ? e.target.valueAsNumber : e.target.value;

    setData({ ...data, [id]: value });
  };
  const handleCategoryInput = (e) => {
    const id = "category"
    const value = e.target.value;
    setCategorySelected(value);

    setData({ ...data, [id]: value });

  };

  const handleSubCategoryInput = (e) => {
    const id = "subcategory"
    const value = e.target.value;
    setsubCategorySelected(value);

    setData({ ...data, [id]: value });
  };


  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      if (location.pathname === "/addproducts") {
        const res = await addDoc(collection(db, "products"), {
          data,
        });
        await setDoc(doc(db, "products", res.id), {
          ...data,
          timeStamp: serverTimestamp(),
        });
      }
      else {
        const res = await createUserWithEmailAndPassword(
          auth,
          data.email,
          data.password
        );
        await setDoc(doc(db, "users", res.user.uid), {
          ...data,
          timeStamp: serverTimestamp(),
        });
      }
      //navigate(-1)
      alert("Product is saved successfully!")
    } catch (err) {
      console.log(err);
    }
  };

  return (

    <div className="new">
      <Sidebar />

      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>

        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>

          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
              {inputData.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  {input.type != "dropdown" ? (
                    <input
                      id={input.id}
                      type={input.type}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                    />) :
                    <> {input.id == "category" ? (
                      <div>
                        <Select id={input.id}
                          labelId="demo-simple-select-label"
                          value={categorySelected}
                          label="Category"
                          onChange={handleCategoryInput}
                        >
                          <MenuItem value="New Arrivals">New Arrivals</MenuItem>
                          <MenuItem value="Bangles">Bangles</MenuItem>
                          <MenuItem value="Necklace">Necklace</MenuItem>
                          <MenuItem value="Chain">Chain Brancelet</MenuItem>
                          <MenuItem value="Brancelet">Chain Brancelet</MenuItem>
                          <MenuItem value="Anklet">Anklet</MenuItem>
                          <MenuItem value="Dropes">Dropes</MenuItem>
                          <MenuItem value="Ring">Ring</MenuItem>
                          <MenuItem value="Name Locket">Name Locket</MenuItem>
                          <MenuItem value="Name Ring">Name Ring</MenuItem>
                        </Select>
                      </div>
                    ) : (
                      <div>
                        <Select id={input.id}
                          labelId="demo-simple-select-label"
                          value={subCategorySelected}
                          label="Sub Category"
                          onChange={handleSubCategoryInput}
                        >
                          <MenuItem value="Antique">Antique</MenuItem>
                          <MenuItem value="Turkish">Turkish</MenuItem>
                          <MenuItem value="Chettinadu">Chettinadu</MenuItem>
                          <MenuItem value="Kolkata">Kolkata</MenuItem>
                          <MenuItem value="Bombay">Bombay</MenuItem>
                          <MenuItem value="Rajkot">Rajkot</MenuItem>
                          <MenuItem value="Kerala">Kerala</MenuItem>
                          <MenuItem value="Handmad Chains">Handmad Chains</MenuItem>
                          <MenuItem value="Italian">Italian</MenuItem>
                          <MenuItem value="Mechiene Chains">Mechiene Chains</MenuItem>
                          <MenuItem value="Rdium Ball Chains">Rdium Ball Chains</MenuItem>
                          <MenuItem value="Cnc Balls">Cnc Balls</MenuItem>
                          <MenuItem value="Molding">Molding</MenuItem>
                          <MenuItem value="Casting">Casting</MenuItem>
                          <MenuItem value="General ">General </MenuItem>
                          <MenuItem value="CNC">CNC</MenuItem>
                          <MenuItem value="Rodium Ball">Rodium Ball </MenuItem>
                          <MenuItem value="Nelluri">Nelluri </MenuItem>
                          <MenuItem value="Stone Ring">Stone Ring </MenuItem>
                        </Select>
                      </div>
                    )
                    }
                    </>
                  }
                </div>

              ))}

              <button disabled={per !== null && per < 100} type="submit">
                Send
              </button>

            </form>

          </div>

        </div>
      </div>

    </div>
  );
};

export default New;
