import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Datatable from "../../components/datatable/Datatable"
import  AuthContext  from "../../context/AuthContext"
import { useContext } from "react";
import { useHistory } from "react-router-dom";


const List = () => {

   const { user } = useContext(AuthContext);
  const history = useHistory();

  console.log(user)
  if (!user) {
    history.push("/pageNotFound");
  }

  
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <Datatable/>
      </div>
    </div>
  )
}

export default List