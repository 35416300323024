import React, { FC, useEffect, useId, useRef, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import ProductCard from "./ProductCard";
import { Product, PRODUCTS } from "data/data";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../utils/firebase.js";

export interface SectionSliderProductCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  data?: Product[];
}

const SectionSliderProductCard: FC<SectionSliderProductCardProps> = ({
  className = "",
  itemClassName = "",
  headingFontClassName,
  headingClassName,
  heading,
  subHeading = "Gold & Diamonds",
  data = PRODUCTS.filter((_, i) => i < 8 && i > 2),
}) => {
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");
  const [arrivalProducts, setData] = useState([]);
  
  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "products"),
      (snapShot) => {
        let list : any = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        list = list.filter((listItem: any) => listItem.category == "New Arrivals")

        setData(list);
        console.log(list)
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    if (!sliderRef.current) {
      return () =>{};
    }

 

    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4 - 1,
        },
        1024: {
          gap: 20,
          perView: 4 - 1,
        },
        768: {
          gap: 20,
          perView: 4 - 2,
        },
        640: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
      type: "slider",
      startAt: 0,
      focusAt: "",
      autoplay: 0,
      hoverpause: false,
      keyboard: false,
      swipeThreshold: 0,
      dragThreshold: 0,
      perSwipe: "",
      touchRatio: 0,
      touchAngle: 0,
      animationDuration: 0,
      rewind: false,
      rewindDuration: 0,
      animationTimingFunc: "",
      waitForTransition: false,
      throttle: 0,
      direction: "ltr",
      peek: "",
      cloningRatio: 0,
      classes: {
        swipeable: "",
        dragging: "",
        direction: {
          ltr: 'glide--ltr',
          rtl: 'glide--rtl'
        },
        type: {
          slider: 'glide--ltr',
          carousel: 'glide--rtl'
        },
        slide:  {
          clone: 'glide--ltr',
          active: 'glide--rtl'
        },
        arrow: {
          disabled: 'glide--ltr'   
                },
        nav: {
          active: 'glide--ltr'   
                },
      }
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`);
    slider.mount();
    // @ts-ignore
    return () => slider.destroy();
  }, [sliderRef, UNIQUE_CLASS]);

 
  
  return (
    <div id="newarrivals">
        <Heading
          className={headingClassName}
          fontClass={headingFontClassName}
          rightDescText={subHeading}
          
        >
          {heading || `New Arrivals`}
        </Heading>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
            {arrivalProducts.map((item, index) => (
              <ProductCard data={item} key={index} />
            ))}
          </div>
        
     
    </div>
  );
};

export default SectionSliderProductCard;
