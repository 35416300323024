import "./products.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import ProductsDatatable from "../../components/ProductsDatatable/ProductsDatatable"
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import  AuthContext  from "../../context/AuthContext"


const Products = () => {
   const { user } = useContext(AuthContext);
  const history = useHistory();

  console.log(user)
  if (!user) {
    history.push("/pageNotFound");
  }
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        {/* <Navbar/> */}
        <ProductsDatatable />
      </div>
    </div>
  )
}

export default Products